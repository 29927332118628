import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/frontend/css/global.css";
import logo from './assets/frontend/img/loading.gif';

// Containers
const FrontLayout = React.lazy(() => import("./containers/FrontLayout"));

const App = (props) => {
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <img className="loader" alt="loader" src={logo} />
    </div>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={loading()}>
        <Switch>
          <Route path="/" render={props => <FrontLayout {...props} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
